<template>
	<div class="partial partial--health-prediction health-prediction">
		<iframe :src="iframeSrc" />
	</div>
</template>

<script>
export default {
	name: 'HealthPartial',
	metaInfo: () => ({
		title: 'Health Prediction',
	}),
	mounted() {
		this.trackVisit()
	},
	computed: {
		iframeSrc() {
			return `https://southstatecorrespondent-tmp.amberoon.com/`
		},
	},
	methods: {
		trackVisit() {
			const track = {
				event_name: 'access',
				app: 'health-prediction',
			}
			this.$store.dispatch(`user/trackMe`, track)
		},
	},
}
</script>

<style scoped lang="scss">
iframe {
	border: 0;
	height: 100%;
	width: 100%;
}
</style>
