<template>
	<div class="view view--embed embed">
		<div class="embed__body">
			<router-view class="embed__header" name="header" />
			<router-view class="embed__content" name="content" />
		</div>
	</div>
</template>

<script>
export default {
	name: 'PartialEmbed',
}
</script>

<style scoped lang="scss">
.partial {
	@include modules.gutter('padding', 0);
}

.embed {
	overflow-x: hidden;
	width: 100%;

	&__body {
		display: grid;
		grid-template-rows: auto 1fr;
		height: 100vh;
	}
}
</style>
