<template>
	<header class="embed-header">
		<div class="embed-header__logo">
			<router-link :to="{ name: 'main' }"><img src="~@/assets/images/correspondent-logo--white-text.png" alt="Correspondent Division" width="200" /></router-link>
		</div>
		<div class="embed-header__nav" :class="{ 'embed-header__nav--open': menuOpen }">
			<router-link :to="{ name: 'main' }" class="button button--text button--reverse button--medium">
				<ion-icon name="apps"></ion-icon>
				<span>Resources</span>
			</router-link>
			<div class="embed-header__avatar" @click="toggleNav" :data-initials="user.firstName | initials"></div>
			<ion-icon name="chevron-down" class="embed-header__nav-arrow" @click="toggleNav"></ion-icon>
			<div class="embed-header__nav-items">
				<router-link :to="{ name: 'profile' }" class="embed-header__nav-item" @click.native="toggleNav">
					<ion-icon name="settings"></ion-icon>
					<span>Settings</span>
				</router-link>
				<a @click="logout" class="embed-header__nav-item">
					<ion-icon name="exit"></ion-icon>
					<span>Logout</span>
				</a>
			</div>
		</div>
	</header>
</template>

<script>
export default {
	name: 'embedHeader',
	data: () => ({
		menuOpen: false,
	}),
	computed: {
		// menuOpen() {
		// 	return this.$store.getters['navigation/menuOpen']
		// },
		user() {
			return this.$store.getters['user/me']
		},
	},
	methods: {
		toggleNav() {
			this.menuOpen = !this.menuOpen
			// this.$store.dispatch('navigation/menuToggle')
		},
		logout() {
			this.$store.dispatch('user/logout').then(() => {
				this.$router.push({ name: 'login' })
			})
		},
	},
	filters: {
		initials(val, count = 2) {
			if (!val) return val
			let words = val.split(' ')
			if (!words.length) return
			let initials = ''
			for (const word of words) {
				initials += word.charAt(0)
			}

			return initials
		}
	}
}
</script>

<style scoped lang="scss">
@use '@material/elevation';

.embed-header {
	@include modules.gutter('padding');
	background-color: modules.color_('primary');
	border-bottom: 1px solid modules.color_('border');
	display: flex;
	align-content: center;
	align-items: center;
	justify-content: space-between;

	&__avatar {
		align-items: center;
		background-color: modules.color_('primary','text');
		border-radius: 50%;
		cursor: pointer;
		display: flex;
		height: 30px;
		justify-content: center;
		margin-right: 10px;
		position: relative;
		width: 30px;

		&:after {
			color: modules.color_('primary');
			// color: white;
			content: attr(data-initials);
			font-size: 10px;
			font-weight: 600;
			letter-spacing: 2px;
		}
	}

	&__nav {
		$nav: &;
		align-items: center;
		display: flex;
		position: relative;

		@include modules.media-query('phone') {
			.button {
				margin-right: auto;
			}
			// justify-content: space-between;
			width: 100%;
		}


		&-arrow {
			color: modules.color_('primary','text');
			cursor: pointer;
			transition: transform 150ms modules.$ease-out;

			#{$nav}--open & {
				transform: rotate(180deg);
			}
		}

		&-items {
			@include elevation.elevation(10);
			background-color: white;
			bottom: 0;
			opacity: 0;
			pointer-events: none;
			position: absolute;
			right: 0;
			transform: translateY(100%);
			transition: all 150ms modules.$ease-out;
			z-index: 5;

			#{$nav}--open & {
				opacity: 1;
				pointer-events: all;
				transform: translateY(120%);
			}
		}

		&-item {
			align-items: center;
			border-top: 1px solid modules.color_('border');
			color: modules.color_('text');
			display: flex;
			@include modules.fontSize(14px);
			font-weight: 500;
			padding: 0.75rem 1rem;
			text-decoration: none;
			transition: all 150ms modules.$ease-standard;

			&:first-of-type {
				border-top: 0;
			}

			&:hover {
				background-color: modules.color_('primary');
				color: modules.color_('primary', 'text');
			}

			span {
				margin-left: 10px;
			}
		}

	}

	.button span {
		margin-left: 10px;
	}

	&__logo {
		display: flex;
		align-items: center;

		@include modules.media-query('phone') {
			display: none;
		}
	}

	&__notifications {
		&-icon {
			color: modules.color_('text', 'light');
			@include modules.fontSize(24px);
			margin-right: 1.5em;
		}
	}
}
</style>
